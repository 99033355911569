import { defineMessages } from "react-intl";

export const exitFormPromptMessages = defineMessages({
  title: {
    id: "TtZg/K",
    defaultMessage: "Would you like to save changes?",
    description: "ExitFormPrompt title",
  },
  unableToSaveTitle: {
    id: "FWWliu",
    defaultMessage: "You have unsaved changes",
    description: "ExitFormPrompt title",
  },
  cancelButton: {
    id: "sn2awN",
    defaultMessage: "Discard changes",
    description: "ExitFormPrompt cancel button",
  },
  confirmButton: {
    id: "MPOj0I",
    defaultMessage: "Save changes",
    description: "ExitFormPrompt confirm button",
  },
  continueEditingButton: {
    id: "1eEyJv",
    defaultMessage: "Continue editing",
    description: "ExitFormPrompt continue editing button",
  },
});
