import { defineMessages } from "react-intl";

export const messages = defineMessages({
  appInstalled: {
    id: "0fM/pV",
    defaultMessage: "App installed",
    description: "message title",
  },
  appRemoved: {
    id: "uIPD1i",
    defaultMessage: "App successfully removed",
    description: "app has been removed",
  },
  appReadyToUse: {
    id: "ZprV2g",
    defaultMessage: "{name} is ready to be used",
    description: "app has been installed",
  },
  appCouldntInstall: {
    id: "5t/4um",
    defaultMessage: "Couldn’t Install {name}",
    description: "message title",
  },
  appActivated: {
    id: "D/+84n",
    defaultMessage: "App activated",
    description: "snackbar text",
  },
  appDeactivated: {
    id: "USO8PB",
    defaultMessage: "App deactivated",
    description: "snackbar text",
  },
});
